








































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AccountDetails',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions']),
  },
})
