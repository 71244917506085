






import Vue from 'vue'
import AccountDetails from '@/components/AccountDetails.vue'

export default Vue.extend({
  name: 'Account',
  components: {
    AccountDetails,
  },
})
